
import LoadingSpinner from '@/components/loading_spinner'

export default {
  name: "CatalogCard"

  components: {
    LoadingSpinner
  }

  props: {
    image_url: { type: String, default: "" },
    name: { type: String, default: "" },
    id: { type: String, default: "" },
    target: { type: String, default: "" },
    is_passive: { type: Boolean, default: false }
  }

  computed: {
    path: ->
      return "/#{@target}" if !@id

      if @target.toLowerCase() == 'product'
        return "/#{@target}/#{@id}/#{@$store.getters['application/product_page_variant']}"
      else "/#{@target}/#{@id}"
  }

  methods: {
    to_next_page: ->
      return if @is_passive

      @$store.commit("#{@target}/bootstrap", { id: @id, name: @name }) if @id
      @navigate()

    navigate: ->
      @$router.push(@path)
  }
}
